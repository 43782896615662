import React, { useState } from 'react';

const ContactForm = () => {
  const [timelyValue, setTimelyValue] = useState('');
  
  const changeDropdown = (e) => {
    setTimelyValue(e.target.value);
  };


  return (
      <div style={styles.container}>
      <h2>Contact Us</h2>
      <form
        id="fs-frm"
        name="survey-form"
        acceptCharset="utf-8"
        action="https://api.web3forms.com/submit"
        method="post"
        style={styles.form}
      >
          
        <fieldset style={styles.fieldset}>
          <label style={styles.label} htmlFor="full-name">
            Full Name
          </label>
          <input
            type="text"
            name="name"
            id="full-name"
            placeholder="First and Last"
            required
            style={styles.input}
          />
          <label style={styles.label} htmlFor="email-address">
            Email Address
          </label>
          <input
            type="email"
            name="_replyto"
            id="email-address"
            placeholder="email-address"
            required
            style={styles.input}
          />
          <label style={styles.label} htmlFor="phone-no">
            Phone No
          </label>
          <input
            type="tel"
            name="phone-no"
            id="phone-no"
            placeholder="phone-no"
            required
            style={styles.input}
          />
          <fieldset style={styles.fieldset}>
            <label style={styles.label} htmlFor="timely">
              Enquiry related to
            </label>
            <select
              name="timely"
              id="timely"
              value={timelyValue}
              required
              onChange={changeDropdown}
              style={styles.input}
            >
              <option value="" disabled>
                Choose
              </option>
              <option value="Training">Training</option>
              <option value="Certification">Certification</option>
            </select>

            {/* Additional options for Training */}
           {timelyValue === 'Training' && (
              <div style={styles.optionContainer}>
                <label style={styles.label} htmlFor="course-type">
                  Courses
                </label>
                <select name="course-type" id="course-type" style={styles.input}>
                    <option value="ISO 9001:2015 (QMS)">ISO 9001:2015 (QMS)</option>
                <option value="ISO 27001 - ISMS">ISO 27001 - ISMS</option>
            <option value="ISO 14001 - EMS">ISO 14001 - EMS</option>
            <option value="ISO 45001 - OHSMS">ISO 45001 - OHSMS</option>
            <option value="ISO 13485:2016 - MDQMS">ISO 13485:2016 (MDQMS)</option>
            <option value="ISO 22000:2018 (FSMS)">ISO 22000:2018 (FSMS)</option>
            <option value="ISO 22301:2019 (BCMS)">ISO 22301:2019 (BCMS)</option>
            <option value="Other Trainings">Other Training</option>
                </select>
              </div>
            )}

            {/* Additional options for Certification */}
           {timelyValue === 'Certification' && (
              <div style={styles.optionContainer}>
                <label style={styles.label} htmlFor="standard">
                  Standard
                </label>
                <select name="standard" id="standard" style={styles.input}>
                  <option value="ISO 9001:2015 (QMS)">ISO 9001:2015 (QMS)</option>
            <option value="ISO 14001:2015 (EMS)">ISO 14001:2015 (EMS)</option>
            <option value="ISO 45001:2018 (OHSMS)">ISO 45001:2018 (OHSMS)</option>
            <option value="ISO 22000:2018 (FSMS)">ISO 22000:2018 (FSMS)</option>
            <option value="ISO/IEC 27001:2013 (ISMS)">ISO/IEC 27001:2013 (ISMS)</option>
            <option value="ISO 22301:2019 (BCMS)">ISO 22301:2019 (BCMS)</option>
            <option value="ISO/IEC 27001:2013 (ISMS)">ISO/IEC 27001:2013 (ISMS)</option>
            <option value="ISO/IEC 20000-1:2018 (IT-SMS)">ISO/IEC 20000-1:2018 (IT-SMS)</option>
            <option value="ISO 13485:2016 - MDQMS">ISO 13485:2016 (MDQMS)</option>
            <option value="HACCP">HACCP</option>
            <option value="Other Certification">Other Certification</option>
            <option value="IMS">IMS</option>
                  
                </select>
              </div>
            )}
          </fieldset>

         <label style={styles.label} htmlFor="message">
            Comments
          </label>
          <textarea
            rows="3"
            name="message"
            id="message"
            placeholder="."
            required
            style={styles.input}
          />
          <input type="hidden" name="_subject" id="email-subject" value="Survey Responses" />
          <input type="hidden" name="access_key" value="96726026-00cc-440f-b516-1e4053302197" />

        </fieldset>
        <input type="submit" value="Send Responses" style={styles.submitButton} />
      </form>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'left',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f5f5f5',
    margin: 0,
    padding: 20,
  },
  form: {
    maxWidth: 600,
    margin: '20px auto',
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  fieldset: {
    border: '1px solid #ddd',
    padding: 10,
    marginBottom: 20,
  },
  label: {
    display: 'block',
    marginBottom: 8,
    color: '#333',
  },
  input: {
    width: '100%',
    padding: 8,
    marginBottom: 16,
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: 4,
     color: 'black'
  },
  optionContainer: {
    marginTop: 10,
  },
  submitButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: 16,
    marginTop: 10,
  },
};

export default ContactForm;

