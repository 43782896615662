import React from "react";

import {
  Box,
  Container,
  Link,
  GridItem,
  Grid,
  Stack,
  HStack,
  Text,
  useColorModeValue,
  Heading,
  chakra,
  VisuallyHidden,
  List,
  ListItem,
  Icon,
} from "@chakra-ui/react";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";
import { MdLocationOn, MdEmail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactForm from "../ContactForm";
import { splittedUID } from "../../utils/sortPages";

const Logo = ({ file }) => {
  return <GatsbyImage image={getImage(file)} alt={"image"} />;
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      target={"__blank"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text
      fontWeight={"bold"}
      fontSize={"2xl"}
      mb={2}
      color="#2b2f69"
      textAlign={"center"}
      _after={{
        content: '""',
        display: "block",
        marginTop: "0.25rem",
        width: "3rem",
        height: "0.15rem",
        backgroundColor: "#2b2f69",
      }}
    >
      {children}
    </Text>
  );
};

export default function Footer() {
  const data = useStaticQuery(graphql`
    {
      allPrismicCertifications {
        nodes {
          uid
          data {
            title {
              text
            }
          }
        }
      }

      file(relativePath: { eq: "bscic.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED)
        }
      }
    }
  `);

  let certificationsInfo = data.allPrismicCertifications.nodes;

  certificationsInfo = certificationsInfo
    .map((node) => {
      const { standard } = splittedUID(node.uid);
      return { ...node, standard };
    })
    .sort((a, b) => a.standard - b.standard);

  return (
    <Box bg={"blue.200"} color={"gray.200"}>
      <Container as={Stack} maxW={"7xl"} py={10}>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(6, 1fr)" }}
          gap={7}
        >
          <GridItem colSpan={{ base: 2 }}>
            <Stack spacing={6} align={"flex-start"}>
              <Box p={3} bg={"white"} rounded="sm">
                <Logo file={data.file} />
              </Box>
              <Heading as="h6" size="md" color="#2b2f69">
                BSCIC Management Systems Certification
              </Heading>

              <Stack
                align={"flex-start"}
                direction={"column"}
                marginTop={6}
                spacing={7}
              >
                <Box flex={1}>
                  <List spacing={3}>
                    <ListItem display={"flex"} alignItems="center">
                      <Icon
                        as={MdLocationOn}
                        color="#2b2f69"
                        sx={{
                          marginRight: "0.5rem",
                        }}
                      />
                      <Text flex={1} color="#2b2f69" fontSize={"xl"}>
                        Office # M-30, Al Gurg Building, Umm Hurair Road,
                        <br />
                        AI Karama, Dubai, United Arab Emirates
                      </Text>
                    </ListItem>
                         <Text
      color="#2b2f69"
      fontWeight={"bold"}
      fontSize={"xl"}
      marginTop={4}
      marginLeft={"2rem"}
    >
      For ISO Certification:
    </Text>
                          <HStack>
                    <ListItem display={"flex"} alignItems="center">
                      <Icon
                        as={MdEmail}
                        color="#2b2f69"
                        sx={{
                          marginRight: "0.5rem",
                        }}
                      />
                      <Text flex={1}>
                        <Link
                          fontSize={"xl"}
                          color="#2b2f69"
                          href="mailto:info.uae@bsc-icc.com"
                          _hover={{
                            color: "white",
                          }}
                        >
                         info.uae@bsc-icc.com
                        </Link>
                      </Text>
                    </ListItem>
                       
                             </HStack>
                   
                               <HStack>
                    <ListItem display={"flex"} alignItems="center">
                      <Icon
                        as={RiWhatsappFill}
                        color="#2b2f69"
                        sx={{
                          marginRight: "0.5rem",
                        }}
                      />
                      <Text flex={1}>
                        <Link
                          href="https://wa.link/4dby44"
                          isExternal
                          color="#2b2f69"
                          fontSize={"xl"}
                          _hover={{
                            color: "white",
                          }}
                        >
                         +971 50 9274320
                        </Link>
                      </Text>
                    </ListItem>
                        
                            </HStack>
                                  <Text
      color="#2b2f69"
      fontWeight={"bold"}
      fontSize={"xl"}
      marginTop={4}
      marginLeft={"2rem"}
    >
     For ISO Training:
    </Text>
                          <HStack>
                    <ListItem display={"flex"} alignItems="center">
                      <Icon
                        as={MdEmail}
                        color="#2b2f69"
                        sx={{
                          marginRight: "0.5rem",
                        }}
                      />
                      <Text flex={1}>
                        <Link
                          fontSize={"xl"}
                          color="#2b2f69"
                          href="mailto:training@bsc-icc.co"
                          _hover={{
                            color: "white",
                          }}
                        >
                        training.uae@bsc-icc.co
                        </Link>
                      </Text>
                    </ListItem>
                          </HStack>  
                           <HStack>
                    <ListItem display={"flex"} alignItems="center">
                      <Icon
                        as={RiWhatsappFill}
                        color="#2b2f69"
                        sx={{
                          marginRight: "0.5rem",
                        }}
                      />
                      <Text flex={1}>
                        <Link
                          href="https://wa.link/3b42kb"
                          isExternal
                          color="#2b2f69"
                          fontSize={"xl"}
                          _hover={{
                            color: "white",
                          }}
                        >
                          +971545308270 
                        </Link>
                      </Text>
                    </ListItem>
                            </HStack>  
                  </List>
                </Box>
              </Stack>

              <Box>
                <Heading as="p" size="md" color="#2b2f69">
                  Connect with Us
                </Heading>
                <Stack direction={"row"} spacing={6} mt={3}>
                  <SocialButton
                    label={"Facebook"}
                    href={"https://www.facebook.com/bscicofficial/"}
                  >
                    <FaFacebook color="#2b2f69" size="sm" />
                  </SocialButton>
                  <SocialButton
                    label={"LinkedIn"}
                    href={
                      "https://www.linkedin.com/company/bscic-certification-pvt-ltd/"
                    }
                  >
                    <FaLinkedin color="#2b2f69" size="sm" />
                  </SocialButton>
                  <SocialButton
                    label={"Instagram"}
                    href={"https://www.instagram.com/bscicofficial/"}
                  >
                    <FaInstagram color="#2b2f69" size="sm" />
                  </SocialButton>
                </Stack>
              </Box>
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Stack align={"flex-start"}>
              <ListHeader>Quick Links</ListHeader>
              <Link
                fontSize={"xl"}
                as={GatsbyLink}
                to={"/about-us"}
                color="#2b2f69"
                _hover={{
                  color: "white",
                }}
              >
                About Us
              </Link>
              <Link
                href={"https://www.bsc-icc.com/careers/"}
                target="__blank"
                isExternal
                color="#2b2f69"
                fontSize={"xl"}
                _hover={{
                  color: "white",
                }}
              >
                Careers
              </Link>
              <Link
                fontSize={"xl"}
                as={GatsbyLink}
                to={"/contact-us"}
                color="#2b2f69"
                _hover={{
                  color: "white",
                }}
              >
                Contact Us
              </Link>
              <Link
                fontSize={"xl"}
                as={GatsbyLink}
                to={"/trainings"}
                color="#2b2f69"
                _hover={{
                  color: "white",
                }}
              >
                Trainings
              </Link>
              <Link
                fontSize={"xl"}
                as={GatsbyLink}
                to={"/certifications"}
                color="#2b2f69"
                _hover={{
                  color: "white",
                }}
              >
                Certifications
              </Link>
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 1 }}>
            <Stack align={"flex-start"}>
              <ListHeader>Standards</ListHeader>
              {certificationsInfo.map((node) => (
                <Link
                  as={GatsbyLink}
                  to={`/certifications/${node.uid}`}
                  key={node.uid}
                  fontSize={"xl"}
                  color="#2b2f69"
                  _hover={{
                    color: "white",
                  }}
                >
                  {node.data.title.text}
                </Link>
              ))}
            </Stack>
          </GridItem>
          <GridItem colSpan={{ base: 2 }}>
            <Stack
              align={"flex-start"}
              sx={{
                "& > *": {
                  width: "100%",
                },
              }}
            >
              <div id="contact-us"></div>
              <ContactForm />
            </Stack>
          </GridItem>
        </Grid>
        <Box textAlign="center">
          <Text
            fontSize={"lg"}
            color="#2b2f69"
            marginBottom="-2rem"
            _before={{
              content: '""',
              display: "block",
              marginTop: "1rem",
              maxWidth: "100%",
              height: "0.025rem",
              backgroundColor: "white",
              marginBottom: "1rem",
            }}
          >
            &copy; {new Date().getFullYear()} BSCIC Management Systems
            Certification. All rights reserved
          </Text>
          {/* <Text fontSize={"xs"} mt={3}>
            Developed By{" "}
            <a href="https://in.linkedin.com/in/pushpend3r">Pushpender Singh</a>
          </Text> */}
        </Box>
      </Container>
    </Box>
  );
}
