import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Icon,
  Link,
  SimpleGrid,
  Popover,
  VisuallyHidden,
  chakra,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
} from "@chakra-ui/react";

import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  // ChevronRightIcon,
} from "@chakra-ui/icons";
import React from "react";
import { graphql, useStaticQuery, Link as GatsbyLink } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"blackAlpha.100"}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      mr={4}
      as={"a"}
      href={href}
      target={"__blank"}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      color="#2b2f69"
      _hover={{
        bg: "blackAlpha.200",
        color: "white",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const TopLinks = () => {
  return (
    <Box bg={"blue.200"} color={"gray.200"}>
      <Container maxW={"7xl"}>
        <SimpleGrid py={2} columns={{ base: "1", md: "1" }}>
          <Flex align={"center"} justify={"center"} wrap={"wrap"}>
            <Box marginRight={"2rem"}>
              <Text color="#2b2f69" fontWeight={"bold"}>For Certification:</Text>
              <Flex align={"center"}>
                <BsFillTelephoneFill color="#2b2f69" marginLeft={"0.5rem"} />
                <Link
                  color="#2b2f69"
                  href="tel:+971509274320"
                  marginLeft={"0.5rem"}
                  _hover={{
                    bg: "blackAlpha.200",
                    color: "white",
                  }}
                >
                  <Text>+971509274320</Text>
                </Link>
                             <Text color="#2b2f69" marginRight={"0.5rem"}> , </Text>

                <HiMail color="#2b2f69" marginLeft={"0.5rem"} />
                <Link
                  color="#2b2f69"
                  href="mailto:info.uae@bsc-icc.com"
                  marginLeft={"0.5rem"}
                  _hover={{
                    bg: "blackAlpha.200",
                    color: "white",
                  }}
                >
                  <Text>info.uae@bsc-icc.com</Text>
                </Link>
              </Flex>
            </Box>

            <Box marginLeft={"2rem"} marginRight={"2rem"}>
              <Text color="#2b2f69" fontWeight={"bold"}>For Training:</Text>
              <Flex align={"center"}>
                <BsFillTelephoneFill color="#2b2f69" marginLeft={"0.5rem"} />
                <Link
                  color="#2b2f69"
                  href="tel:+971545308270"
                  marginLeft={"0.5rem"}
                  _hover={{
                    bg: "blackAlpha.200",
                    color: "white",
                  }}
                >
                  <Text>+971545308270</Text>
                </Link>
            <Text color="#2b2f69" marginRight={"0.5rem"}> , </Text>
                <HiMail color="#2b2f69" marginLeft={"0.5rem"} />
                <Link
                  color="#2b2f69"
                  href="mailto:training.uae@bsc-icc.co"
                  marginLeft={"0.5rem"}
                  _hover={{
                    bg: "blackAlpha.200",
                    color: "white",
                  }}
                >
                  <Text>training.uae@bsc-icc.co</Text>
                </Link>
              </Flex>
            </Box>

            <Flex align={"center"} justify={"center"} marginLeft={"2rem"}>
              <SocialButton
                label={"Facebook"}
                href={"https://www.facebook.com/bscicofficial/"}
                marginRight={"0.5rem"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton
                label={"LinkedIn"}
                href={
                  "https://www.linkedin.com/company/bscic-certification-pvt-ltd/"
                }
                marginRight={"0.5rem"}
              >
                <FaLinkedin />
              </SocialButton>
              <SocialButton
                label={"Instagram"}
                href={"https://www.instagram.com/bscicofficial/"}
              >
                <FaInstagram />
              </SocialButton>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Container>
    </Box>
  );
};



export default function Header() {
  const { isOpen, onToggle } = useDisclosure();
  const queryResult = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "bscic.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <Box borderBottom={1} borderStyle={"solid"} borderColor={"gray.200"}>
      <TopLinks />
      <Container maxW={"7xl"}>
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "start" }}
            align={{ base: "center" }}
          >
            <Link
              as={GatsbyLink}
              to="/"
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
            >
              <GatsbyImage image={getImage(queryResult.file)} alt={"image"} />
            </Link>
            <Flex display={{ base: "none", md: "flex" }} mx="auto">
              <DesktopNav />
            </Flex>
          </Flex>
          <Flex
            flex={{ md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Container>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = "blue.700";
  const linkHoverColor = "blue.400";
  const popoverContentBgColor = "white";

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem, i) => (
        <Box key={i}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                as={GatsbyLink}
                to={navItem.href}
                p={2}
                fontSize={"lg"}
                marginLeft="1.2rem"
                fontWeight={600}
                color={linkColor}
                bg="none"
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"xs"}
              >
                <Stack>
                  {navItem.children.map((child, i) => (
                    <DesktopSubNav key={i} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      as={GatsbyLink}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("blue.50", "gray.300") }}
      target="__blank"
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "blue.500" }}
            fontWeight={600}
            fontSize={"md"}
          >
            {label}
          </Text>
        </Box>
        {/* <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex> */}
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem, i) => (
        <MobileNavItem key={i} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={GatsbyLink}
        to={(children && "#") || href}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={600} color={"gray.600"}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={"gray.200"}
          align={"start"}
        >
          {children &&
            children.map((child, i) => (
              <Link key={i} py={2} as={GatsbyLink} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "About Us",
    href: "/#about-us",
  },
  {
    label: "Certifications",
    href: "/certifications",

    children: [
      {
        label: "ISO 9001:2015 (QMS)",
        href: "/certifications/iso-9001",
      },
      {
        label: "ISO 14001:2015 (EMS)",
        href: "/certifications/iso-14001",
      },
      {
        label: "ISO 45001:2018 (OHSMS)",
        href: "/certifications/iso-45001",
      },
      {
        label: "ISO 22000:2018 (FSMS)",
        href: "/certifications/iso-22000",
      },
      {
        label: "ISO/IEC 27001:2013 (ISMS)",
        href: "/certifications/iso-27001",
      },
      {
        label: "ISO/IEC 20000-1:2018 (IT-SMS)",
        href: "/certifications/iso-20000-1",
      },
      {
        label: "ISO 13485:2016 (MD-QMS)",
        href: "/certifications/iso-13485",
      },
      {
        label: "ISO 22301:2019 (BCMS)",
        href: "/certifications/iso-22301",
      },
      {
        label: "HACCP",
        href: "/certifications/haccp",
      },
    ],
  },
  {
    label: "Trainings",
    href: "/trainings",
    children: [
      {
        label: "Lead Auditor",
        href: "/trainings/lead-auditor",
      },
      {
        label: "Internal Auditor",
        href: "/trainings/internal-auditor",
      },
      {
        label: "Awareness",
        href: "/trainings/awareness",
      },
      {
        label: "Advance Implementation",
        href: "/trainings/advance-implementation",
      },
      {
        label: "Soft Skill",
        href: "/trainings/soft-skills",
      },
    ],
  },

  {
    label: "Training Calendar",
    href: "#",

    children: [
      {
        label: "Exemplar Global",
        href: "/training-calendars/exemplar-global",
      },
      {
        label: "CQI and IRCA",
        href: "/training-calendars/cqi-irca",
      },
    ],
  },
  {
    label: "Contact Us",
    href: "/contact-us",
  },
];
