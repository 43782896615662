const sortPages = pages => {
  const _pages = pages.map(page => {
    page.uid = splittedUID(page.uid);
    return page;
  });

  return [
    ..._pages.sort(
      (pageOne, pageTwo) => pageOne.uid.standard - pageTwo.uid.standard
    ),
  ];
};

const splittedUID = id => {
  const _uid = id.split("_");

  // For Certification Page
  if (_uid.length === 1) {
    return {
      standard: _uid[0].split("-")[1] || _uid[0].split("-")[0],
      catagory: "",
      ab: "",
      slug: "",
    };
  }

  return {
    catagory: _uid[0],
    ab: _uid[1],
    standard: _uid[2].split("-")[1] || _uid[2].split("-")[0],
    slug: _uid[2],
  };
};

export { sortPages, splittedUID };
